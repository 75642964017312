import AxiosService from "@/services/AxiosService";
class PanelService {
  getNews() {
    return AxiosService.get("/news")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getFinancesHistory() {
    return AxiosService.get("/finances/history")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  withdrawFromWallet(payload) {
    return AxiosService.post("/finances/withdraw", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getReferals() {
    return AxiosService.get("/user/referrals")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getUserData() {
    return AxiosService.get("/user")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getWallets() {
    return AxiosService.get("/user/wallets")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getCategories() {
    return AxiosService.get("/campaigns-categories")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getUrlTemplates() {
    return AxiosService.get("/subaccount-url-templates")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  updateLanguage(lang) {
    return AxiosService.patch("/user/language", lang)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  sendForm(form) {
    return AxiosService.post("/moderation-form", form)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  enable2FA(code) {
    return AxiosService.post("/user/enable-g2a", code)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  disable2FA(code) {
    return AxiosService.post("/user/disable-g2a", code)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getQr() {
    return AxiosService.get("/user/g2a-qr", {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async updateWallet(payload) {
    return await AxiosService.post("/user/wallets", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async resendMail(payload) {
    return await AxiosService.post("/user/wallets/resend-email", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async verifyWallet(payload) {
    return await AxiosService.get("/user/wallet-verify", { params: payload });
  }
  async updateUserData(payload) {
    return await AxiosService.post("/user/profile", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async updatePassword(payload) {
    return await AxiosService.post("/user/change-password", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getLogList() {
    return await AxiosService.get(`/users/logs`)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new PanelService();
